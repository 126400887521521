<template>
    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Spec" transform="translate(-987.000000, -650.000000)" :fill="isThemeDark ? '#000000' : '#fff'" fill-rule="nonzero">
                <g id="tv" transform="translate(987.300000, 649.657394)">
    <path d="M13 46q-1.2 0-2.1-.9-.9-.9-.9-2.1V5q0-1.2.9-2.1.9-.9 2.1-.9h22q1.2 0 2.1.9.9.9.9 2.1v38q0 1.2-.9 2.1-.9.9-2.1.9Zm0-7.5V43h22v-4.5Zm11 3.75q.65 0 1.075-.425.425-.425.425-1.075 0-.65-.425-1.075-.425-.425-1.075-.425-.65 0-1.075.425-.425.425-.425 1.075 0 .65.425 1.075.425.425 1.075.425ZM13 35.5h22v-26H13Zm0-29h22V5H13Zm0 32V43Zm0-32V5v1.5Z"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
import { eventBus } from "@/eventBus";
export default {
    name: "Phonesvg",
    data() {
        return{
            isThemeDark: true,
        }
    },
    created() {
        eventBus.$on("theme-change", (data) =>{
            if(data == 'theme-dark'){
                this.isThemeDark = true
            }else{
                this.isThemeDark = false
            }
        })
    },
    mounted() {        
    let currentTheme = localStorage.getItem('theme');
    if(currentTheme == 'theme-light'){
      this.isThemeDark = false
    }else{
      this.isThemeDark = true
    }
    },
  };
</script>

<style>
</style>